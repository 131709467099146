import React from "react";
import { Link } from "gatsby";
import BlogImage from "../../static/img/blog-image.jpg";
import BlogAuthorImage from "../../static/img/blog-author.jpg";
import Image from "../components/common/Image";
import { staticPage } from "../../config/staticPage";
import Layout from "../layout/Layout";

function BlogPostPage() {
  return (
    <>
      <div className="page-wrapper">
        <div className="container">
          Show Breadcrumb here
          <br />
          <br />
        </div>

        <div className="blog-post-section">
          <div className="container-x-small">
            <div className="blog-post-title">
              <h1 className="font-bold text-center">
                Midwest Goods, Inc offers Office & Warehouse positions.
              </h1>
            </div>
            <div className="blog-post-content">
              <div className="blog-post-image">
                <Image
                  src={BlogImage}
                  alt="Blog Image"
                />
              </div>
              <p>
                We also offer a competitive benefits package. We are committed
                to creating a workplace that inspires and enables our employees
                to be the best they can be so that we can grow as a company,
                team, and family.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu
                velit tempus erat egestas efficitur. In hac habitasse platea
                dictumst. Fusce a nunc eget ligula suscipit finibus. Aenean
                pharetra quis lacus at viverra.
              </p>
              <p>
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himenaeos. Aliquam quis posuere ligula. In
                eu dui molestie, molestie lectus eu, semper lectus.
              </p>

              <h2>Next on the pipeline</h2>
              <p>
                Duis eu velit tempus erat egestas efficitur. In hac habitasse
                platea dictumst. Fusce a nunc eget ligula suscipit finibus.
                Aenean pharetra quis lacus at viverra. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos.
              </p>
              <p>
                Morbi efficitur auctor metus, id mollis lorem pellentesque id.
                Nullam posuere maximus dui et fringilla.
              </p>
              <p>A list looks like this:</p>
              <ul>
                <li>First item in the list</li>
                <li>
                  Second item in the list lorem ipsum dolor sit amet nunc felis
                  dolor lorem ipsum sit amet
                </li>
                <li>Third item in the list</li>
              </ul>
              <p>
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himenaeos. Aliquam quis posuere ligula. In
                eu dui molestie, molestie lectus eu, semper lectus.
              </p>
              <p>
                Class aptent taciti sociosqu ad litora torquent per conubia
                nostra, per inceptos himenaeos. Aliquam quis posuere ligula. In
                eu dui molestie, molestie lectus eu, semper lectus.
              </p>
            </div>
            <div className="blog-bottom-section">
              <div className="blog-author-block flex vertical-middle">
                <div className="blog-author-img">
                  <Image src={BlogAuthorImage} alt="Blog Author Image" />
                </div>
                <div className="blog-author-detail">
                  <h4>Mika MAtikainen</h4>
                  <p>
                    <span>Apr 15, 2023</span> · <span>10:30 am</span>
                  </p>
                </div>
              </div>
              <div className="blog-tag-section flex vertical-middle">
                <p>Tags:</p>
                <p className="tag-list">
                  <Link>product design</Link>
                  <Link>culture</Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="blog-related-section">
          <div className="container-small">
            <div className="blog-list flex flex-wrap vertical-top">
              <div className="blog-list-item">
                <div className="blog-image-block">
                  <Link to={"/"}>
                    <Image src={BlogImage} alt="Blog Image" />
                  </Link>
                </div>
                <div className="blog-detail-block">
                  <h2 className="font-bold h3">
                    <Link to={"/"}>
                      Midwest Goods, Inc offers Office & Warehouse positions.
                    </Link>
                  </h2>
                  <p>
                    We also offer a competitive benefits package. We are
                    committed to creating a workplace that inspires and enables
                    our employees to be the best they can be so that we can grow
                    as a company, team, and family.
                  </p>
                  <Link className="read-more-link">Read More</Link>
                </div>
              </div>

              <div className="blog-list-item">
                <div className="blog-image-block">
                  <Link to={"/"}>
                    <Image src={BlogImage} alt="Blog Image" />
                  </Link>
                </div>
                <div className="blog-detail-block">
                  <h2 className="font-bold h3">
                    <Link to={"/"}>
                      Midwest Goods, Inc offers Office & Warehouse positions.
                    </Link>
                  </h2>
                  <p>
                    We also offer a competitive benefits package. We are
                    committed to creating a workplace that inspires and enables
                    our employees to be the best they can be so that we can grow
                    as a company, team, and family.
                  </p>
                  <Link className="read-more-link">Read More</Link>
                </div>
              </div>

              <div className="blog-list-item">
                <div className="blog-image-block">
                  <Link to={"/"}>
                    <Image src={BlogImage} alt="Blog Image" />
                  </Link>
                </div>
                <div className="blog-detail-block">
                  <h2 className="font-bold h3">
                    <Link to={"/"}>
                      Midwest Goods, Inc offers Office & Warehouse positions.
                    </Link>
                  </h2>
                  <p>
                    We also offer a competitive benefits package. We are
                    committed to creating a workplace that inspires and enables
                    our employees to be the best they can be so that we can grow
                    as a company, team, and family.
                  </p>
                  <Link className="read-more-link">Read More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "blog-post") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

BlogPostPage.Layout = Layout
export default BlogPostPage;
